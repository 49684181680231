import './src/styles/global.css'
import './src/App.css';
import './src/index.css';

export const onClientEntry = () => {
  // Function to load scripts with cache busting
  const loadScript = (src, attributes = {}, async = true, defer = true) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src + `?v=${new Date().getTime()}`; // Cache busting
      script.async = async;
      script.defer = defer;

      Object.keys(attributes).forEach(key => {
        script.setAttribute(key, attributes[key]);
      });

      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const addPreconnectLink = (href) => {
    const link = document.createElement("link");
    link.rel = "preconnect";
    link.href = href;
    link.crossOrigin = "anonymous";
    document.head.appendChild(link);
  };

  // Add preconnect links
  addPreconnectLink("https://cdnjs.cloudflare.com");
  addPreconnectLink("https://kit.fontawesome.com");
  addPreconnectLink("https://chatbot.srivengroups.com");
  addPreconnectLink("https://cdn.jsdelivr.net");
  addPreconnectLink("https://fonts.googleapis.com");

  // const googleAdScript = document.createElement("script");
  // googleAdScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9676921778226069";
  // googleAdScript.async = true;
  // googleAdScript.crossOrigin = "anonymous";
  // document.head.appendChild(googleAdScript);


  // const googleSearchScript = document.createElement("script");
  // googleSearchScript.src = "https://cse.google.com/cse.js?cx=6620d447a14c640a0";
  // googleSearchScript.async = true;
  // document.head.appendChild(googleSearchScript);

  

  const bootstrapScript = document.createElement("script");
  bootstrapScript.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js";
  bootstrapScript.crossOrigin = "anonymous";
  bootstrapScript.integrity = "sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz";
  document.body.appendChild(bootstrapScript);

  loadScript("https://chatbot.srivengroups.com/chatbot.js", { class: "chatbot-script" });
};
